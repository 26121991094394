import { type Config as RapidConfig } from "@vzmi/types-rapid";
import { type DeviceSize } from "@yahoo-commerce/util";
import { type PAGES } from "@yahoo-commerce/util/lib/slug";
import {
  GAM_EMEA_CONSENT_KEYS,
  GAM_NPA_CONSENT_KEYS,
  GAM_NPA_NON_CONSENT_KEYS,
  GAM_REST_OF_WORLD_CONSENT_KEYS,
  GAM_US_CONSENT_KEYS,
} from "@/configs/benji";
import { SPACE_IDS } from "@/configs/spaceId";
import { type RequestContext } from "@/lib/request/types";
import { type CaasContentI13n, type CaasArticle } from "@/types/Caas";

export const getSpaceId = ({
  contentI13n,
  deviceSize,
  site,
}: {
  contentI13n?: CaasContentI13n;
  deviceSize?: DeviceSize;
  site: string;
}): number => {
  let spaceId = 0;

  if (contentI13n) {
    spaceId =
      (deviceSize && contentI13n?.spaceIds?.[deviceSize]) ??
      Number(contentI13n?.spaceId);
  } else if (site && deviceSize) {
    spaceId = SPACE_IDS[site]?.[deviceSize];
  }

  if (Number.isNaN(spaceId)) {
    return 0;
  }

  return spaceId;
};

export interface RapidConfigInput {
  deviceSize: DeviceSize;
  requestContext: RequestContext;
  pageName: PAGES;
  isPerpetualPost?: boolean;
  hasEditorialReadMore?: boolean;
  referrerUrl?: string;
  extraKeys?: Record<string, string>;
}

/**
 * Returns base config required to init Rapid JS
 * @param requestContext Per request context
 * @param deviceSize Device size
 * @returns Base config to init Rapid JS
 */
const getBaseRapidConfig = (
  spaceId: number,
  { cloudRegion, partner, region, bucket, intl }: RequestContext,
): RapidConfig => ({
  async_all_clicks: true,
  // @ts-ignore
  click_timeout: 300,
  client_only: 1,
  compr_type: "deflate",
  keys: {
    A_id: spaceId || "",
    colo: cloudRegion,
    mrkt: intl,
    partner,
    version: region,
  },
  pageview_on_init: true,
  perf_navigationtime: 2,
  perf_resourcetime: 1,
  spaceid: spaceId,
  test_id: bucket || [],
  tracked_mods_viewability: [],
  viewability: true,
  webworker_file: "https://s.yimg.com/ss/rapidworker-1.2.js",
  yql_host: "udc.yahoo.com",
});

/**
 * Returns config required to init Rapid JS for shopping homepage
 * @param requestContext Per request context
 * @param deviceSize Device size
 * @returns Config to init Rapid JS
 */
export const getRapidConfigForShopping = ({
  deviceSize,
  requestContext,
  pageName,
}: RapidConfigInput): RapidConfig => {
  const spaceId = getSpaceId({ deviceSize, site: requestContext.site });
  const baseConfig = getBaseRapidConfig(spaceId, requestContext);
  return {
    ...baseConfig,
    keys: {
      ...baseConfig.keys,
      layout: "grid",
      p_sec: "campaign",
      pg_name: pageName,
      pt: pageName,
      ptype: "grid",
    },
    // @ts-ignore
    tag_id: "G-NMBHR41WNC",
  };
};

/**
 * Returns config required to init Rapid JS for tech homepage
 * @param requestContext Per request context
 * @param deviceSize Device size
 * @returns Config to init Rapid JS
 */
export const getRapidConfigForTechHomepage = ({
  deviceSize,
  requestContext,
  pageName,
}: RapidConfigInput): RapidConfig => {
  const spaceId = getSpaceId({
    deviceSize,
    site: requestContext.site ?? "tech",
  });
  const baseConfig = getBaseRapidConfig(spaceId, requestContext);
  return {
    ...baseConfig,
    keys: {
      ...baseConfig.keys,
      pg_name: pageName,
      pt: pageName,
      ver: "csa",
    },
  };
};

/**
 * Returns config required to init Rapid JS for local homepage
 * @param requestContext  Per request context
 * @param deviceSize Device size
 * @returns Config to init Rapid JS
 */
export const getRapidConfigForLocalHomepage = ({
  deviceSize,
  requestContext,
  pageName,
}: RapidConfigInput): RapidConfig => {
  const spaceId = getSpaceId({ deviceSize, site: "local" });
  const baseConfig = getBaseRapidConfig(spaceId, requestContext);
  return {
    ...baseConfig,
    keys: {
      ...baseConfig.keys,
      pg_name: pageName,
      pt: pageName,
      ver: "csa",
    },
  };
};

/**
 * Returns config required to init Rapid JS for Health homepage
 * @param requestContext  Per request context
 * @param deviceSize Device size
 * @returns Config to init Rapid JS
 */
export const getRapidConfigForHealthHomepage = ({
  deviceSize,
  requestContext,
  pageName,
}: RapidConfigInput): RapidConfig => {
  const spaceId = getSpaceId({
    deviceSize,
    site: requestContext.site ?? "health",
  });
  const baseConfig = getBaseRapidConfig(spaceId, requestContext);
  return {
    ...baseConfig,
    keys: {
      ...baseConfig.keys,
      pg_name: pageName,
      pt: pageName,
      ver: "csa",
    },
  };
};

export const getRapidConfigForArticlePage = ({
  deviceSize,
  requestContext,
  pageName,
  isPerpetualPost,
  hasEditorialReadMore,
  referrerUrl,
  extraKeys,
}: RapidConfigInput): RapidConfig => {
  const spaceId = getSpaceId({
    deviceSize,
    site: requestContext.site ?? "shopping",
  });
  const baseConfig = getBaseRapidConfig(spaceId, requestContext);
  return {
    ...baseConfig,
    keys: {
      ...baseConfig.keys,
      _R: referrerUrl ?? "",
      expn: isPerpetualPost ? "perpetual-post" : "",
      pct: "story",
      pg_name: pageName,
      pl2: hasEditorialReadMore
        ? "seamless-article-editorial"
        : "seamless-article",
      pt: "content",
      ver: "react",
      ...extraKeys,
    },
  };
};

export const getBenjiConfigForHomepage = ({
  deviceSize,
  requestContext,
}: {
  deviceSize: DeviceSize;
  requestContext: RequestContext;
}) => {
  const { features, intl, lang, partner, region, site } = requestContext;
  return {
    i13n: {
      abk: "",
      bucket: requestContext.bucket,
      designtype: "viperStream",
      dir: "ltr",
      feature: Object.keys(features),
      intl: intl,
      lang: lang,
      mode: "normal",
      mrkt: intl,
      pageName: "homepage",
      pageType: "default",
      partner,
      pt: "home",
      region: region,
      site,
      spaceid: String(getSpaceId({ deviceSize, site })),
      ver: "csa",
      version: region,
      ynet: "0",
    },
  };
};

export const getBenjiConfigForArticle = ({
  deviceSize,
  requestContext,
}: {
  deviceSize: DeviceSize;
  requestContext: RequestContext;
}) => {
  const { features, intl, lang, partner, region, site } = requestContext;
  return {
    i13n: {
      abk: "",
      bucket: requestContext.bucket,
      contentSite: requestContext.site,
      designtype: "viperStream",
      dir: "ltr",
      feature: Object.keys(features),
      intl: intl,
      lang: lang,
      mode: "normal",
      mrkt: intl,
      pageName: "article",
      pageType: "default",
      partner,
      pct: "story",
      pt: "content",
      region: region,
      site,
      spaceid: String(getSpaceId({ deviceSize, site })),
      ver: "article",
      version: region,
      ynet: "0",
    },
  };
};

export const getBenjiConfigForCategory = ({
  deviceSize,
  requestContext,
}: {
  deviceSize: DeviceSize;
  requestContext: RequestContext;
}) => {
  const { features, intl, lang, partner, region, site } = requestContext;
  return {
    i13n: {
      abk: "",
      bucket: requestContext.bucket,
      designtype: "viperStream",
      dir: "ltr",
      feature: Object.keys(features),
      intl: intl,
      lang: lang,
      mode: "normal",
      mrkt: intl,
      pageName: "category",
      pageType: "default",
      partner,
      pt: "category",
      region: region,
      site,
      spaceid: String(getSpaceId({ deviceSize, site })),
      ver: "csa",
      version: region,
      ynet: "0",
    },
  };
};

export const getBenjiConfigForNotFound = ({
  deviceSize,
  requestContext,
}: {
  deviceSize: DeviceSize;
  requestContext: RequestContext;
}) => {
  const { features, intl, lang, partner, region, site } = requestContext;
  return {
    i13n: {
      abk: "",
      bucket: requestContext.bucket,
      designtype: "viperStream",
      dir: "ltr",
      feature: Object.keys(features),
      intl: intl,
      lang: lang,
      mode: "normal",
      mrkt: intl,
      pageName: "404",
      pageType: "default",
      partner,
      pt: "not-found",
      region: region,
      site: requestContext.site,
      spaceid: String(getSpaceId({ deviceSize, site })),
      ver: "csa",
      version: region,
      ynet: "0",
    },
  };
};

export const getBenjiConsent = (
  jurisdiction: string,
  consentFields?: string[],
) => {
  const consent = {
    allowOnlyLimitedAds: false,
    allowOnlyNonPersonalizedAds: false,
  };
  if (!consentFields) {
    return consent;
  }

  // Logic here was copied from express-monetization
  // https://git.ouryahoo.com/monetization/monorepo/blob/main/packages/express-monetization/src/privacyCheck.ts

  // ---------- Check rules for limted ads ----------
  if (jurisdiction === "GDPR") {
    // Apply consent rules for EMEA
    // all of these keys must be opted in, otherwise show limited ads
    consent.allowOnlyLimitedAds = GAM_EMEA_CONSENT_KEYS.some(
      (key) => consentFields.includes(key) === false,
    );
  } else if (jurisdiction === "US") {
    // Apply consent rules for US
    // all of these keys must be opted in, otherwise show limited ads
    consent.allowOnlyLimitedAds = GAM_US_CONSENT_KEYS.some(
      (key) => consentFields.includes(key) === false,
    );
  } else {
    // Apply consent rules for rest of the world
    // all of these keys must be opted in, otherwise show limited ads
    consent.allowOnlyLimitedAds = GAM_REST_OF_WORLD_CONSENT_KEYS.some(
      (key) => consentFields.includes(key) === false,
    );
  }

  // ---------- Check rules for non-personalized ads -----------
  const hasNPANonConsentKeys = GAM_NPA_NON_CONSENT_KEYS.every(
    (key) => consentFields.includes(key) === false,
  );
  // If opted out allowed dissent keys but opted in all consent ones, show non-personalized ads
  consent.allowOnlyNonPersonalizedAds =
    hasNPANonConsentKeys &&
    GAM_NPA_CONSENT_KEYS.every((key) => consentFields.includes(key) !== false);

  return consent;
};

/**
 * Parses the article's `adMeta.rs` string, which is formatted like:
 *
 * ```
 * "lmsid:a0a6T00000PvqBIQAZ;revsp:fox_weather_videos_202;lpstaid:4891b17b-4789-3b6a-b83d-25668b27705c;..."
 * ```
 *
 * and converts it into a key-value mapping like
 *
 * ```
 *  {
 *    lmsid: "a0a6T00000PvqBIQAZ",
 *    lpstaid: "4891b17b-4789-3b6a-b83d-25668b27705c",
 *    revsp: "fox_weather_videos_202",
 *    ...
 *  }
 * ```
 */
export const parseArticleAdMetaRs = (
  article: CaasArticle,
): Record<string, string> =>
  (article.data?.contentMeta?.adMeta?.rs || "")
    .split(";")
    .map((serializedEntry) => serializedEntry.split(":"))
    .filter((entry): entry is [string, string] => entry.length === 2)
    .reduce(
      (entries, [key, value]) => ({ ...entries, [key]: value }),
      {} as Record<string, string>,
    );

const siteAttributePattern = /^([^=]+)="(.*)"$/;
const badAttributePattern = /%-/g;
const parseArticleAdMetaSiteAttribute = (
  serializedAttribute: string,
): [string, string] | null => {
  const matches = serializedAttribute.match(siteAttributePattern);
  if (!matches) {
    return null;
  }

  try {
    // sports articles sometimes have %- in the hashtag value
    return [
      matches[1],
      decodeURIComponent(matches[2].replace(badAttributePattern, "-")),
    ];
  } catch (e: any) {
    // TODO: temporary code to see if we can trigger this just to validate / see how often we hit this
    // decodeURIComponent('hello%91world') will throw URIError: URI malformed
    // We must be getting some bad encodings from CaaS...
    if (e) {
      e.serializedAttribute = serializedAttribute;
    }
    return null;
  }
};

/**
 * Parses the article's `adMeta.site_attribute` string, which is formatted like:
 *
 * ```
 * "wiki_topics=\"Francis_Scott_Key_Bridge_%28Baltimore%29;Container_ship;Baltimore;Tuesday_Morning\" ctopid=\"1106000;1107000\" ..."
 * ```
 *
 * and converts it into a key-value mapping like
 *
 * ```
 *  {
 *    ctopid: "1106000;1107000",
 *    wiki_topics: "Francis_Scott_Key_Bridge_(Baltimore);Container_ship;Baltimore;Tuesday_Morning",
 *    ...
 *  }
 * ```
 */
export const parseArticleAdMetaSiteAttributes = (
  article: CaasArticle,
): Record<string, string> =>
  (article.data?.contentMeta?.adMeta?.site_attribute || "")
    .split(/\s+/)
    .map(parseArticleAdMetaSiteAttribute)
    .filter((entry): entry is [string, string] => entry?.length === 2)
    .reduce(
      (entries, [key, value]) => ({ ...entries, [key]: value }),
      {} as Record<string, string>,
    );
