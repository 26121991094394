"use client";

import { type Config } from "@vzmi/types-rapid";
import {
  Rapid,
  beaconClick,
  beaconPageview,
  reInit,
} from "@yahoo-commerce/i13n";
import { useDeviceSize } from "@yahoo-commerce/util";
import { type PAGES } from "@yahoo-commerce/util/lib/slug";
import { type FC, useEffect, useMemo, useState } from "react";
import { useCurrentPath, usePreviousPath } from "@/contexts/PathProvider";
import { useSeamlessSlugChange } from "@/hooks/events";
import { getClickThroughUrl } from "@/lib/article/util";
import {
  getRapidConfigForArticlePage,
  getRapidConfigForHealthHomepage,
  getRapidConfigForLocalHomepage,
  getRapidConfigForShopping,
  getRapidConfigForTechHomepage,
} from "@/lib/i13n";
import { useRequestContext } from "@/lib/request/client";
import { type CaasArticle } from "@/types/Caas";
import { RapidConfigMapType } from "./type";

const rapidConfigMap = {
  article: getRapidConfigForArticlePage,
  health: getRapidConfigForHealthHomepage,
  local: getRapidConfigForLocalHomepage,
  shopping: getRapidConfigForShopping,
  tech: getRapidConfigForTechHomepage,
};

interface Props {
  type?: RapidConfigMapType;
  pageName: PAGES;
  article?: CaasArticle;
  extraKeys?: Record<string, string>;
}

export const RapidConfig: FC<Props> = ({
  type = RapidConfigMapType.shopping,
  pageName,
  article: initialArticle,
  extraKeys = {},
}) => {
  const requestContext = useRequestContext();
  const deviceSize = useDeviceSize();
  const path = useCurrentPath();
  const previousPath = usePreviousPath();
  const [lastPageViewPath, setLastPageViewPath] = useState(path);

  const slugState = useSeamlessSlugChange(
    initialArticle
      ? {
          article: initialArticle,
          index: 0,
          slug: new URL(getClickThroughUrl(initialArticle)).toString(),
        }
      : null,
  );
  const [hasEditorialReadMore, setHasEditorialReadMore] = useState(false);

  const config = useMemo((): Config | undefined => {
    let config;
    if (!deviceSize) return;

    // when seed article
    if (slugState?.index === 0) {
      const { readMoreList, isReadMoreListOverridden } =
        slugState.article.data.contentMeta.contentIdentifiers;
      if (readMoreList && !isReadMoreListOverridden && !hasEditorialReadMore)
        setHasEditorialReadMore(true);
    }

    if (rapidConfigMap[type]) {
      let referrerUrl;
      if (window && document) {
        referrerUrl = previousPath
          ? window.location.host + previousPath
          : document.referrer;
      }

      config = rapidConfigMap[type]({
        deviceSize,
        extraKeys,
        pageName,
        requestContext,
        ...(type === "article" && {
          hasEditorialReadMore,
          isPerpetualPost: previousPath?.endsWith(".html"),
          referrerUrl,
        }),
      });
    }

    return config;
  }, [
    deviceSize,
    type,
    extraKeys,
    pageName,
    requestContext,
    previousPath,
    hasEditorialReadMore,
    slugState?.article,
    slugState?.index,
  ]);

  useEffect(() => {
    const currentPath = window.location.pathname;
    if (
      window.rapidInstance &&
      config &&
      currentPath === path &&
      lastPageViewPath !== currentPath
    ) {
      beaconClick({ options: { dwell: "stop" }, sec: "rapidDwell", slk: "" });
      reInit(config);
      // reInit should have beaconed but it isn't. Manually fire it now.
      beaconPageview();
      setLastPageViewPath(currentPath);
    }
  }, [config, lastPageViewPath, path]);

  if (!config) {
    return null;
  }

  return <Rapid config={config} />;
};
